<header id="header__layout" class="w-full bg-secondary lg:p-3 py-3 px-5 relative z-[2] border-b border-b-third">
	<nav class="grid lg:grid-cols-[minmax(0,290px)_minmax(0,1fr)_minmax(0,290px)] grid-cols-2 gap-4 items-center w-[1440px] max-w-full mx-auto">
		<div class="flex flex-wrap gap-8 items-center">
			<div class="content__logo flex items-center gap-3 sm:pl-5 pl-4">
				<div class="lg:hidden block">
					<a class="btn__sidebar md:text-2xl text-xl block w-[25px] h-[25px] cursor-pointer text-white">
						<i class="fa-light fa-bars"></i>
					</a>
				</div>
				<div class="content__logo-img lg:block max-w-full hidden">
					<img [src]="env.imgLogo" [alt]="env.title">
				</div>
			</div>
		</div>

		<div class="w-full lg:block hidden text-left">
			<ul class="inline-flex flex-wrap gap-4">
				@for (item of menu; track $index) {
					<li>
						@if (item.external) {
							<a [href]="item.url" target="_blank" class="text-white font-semibold hover:text-primary">{{ item.name }}</a>
						} @else {
							@if(item.submenu) {
								<dropdown-menu-cy [title]="item.name">
									<ul class="flex flex-col gap-4">
										@for (subitem of (item?.submenu ?? []); track $index) {
											<li>
												@if (subitem.external) {
													<a [href]="item.url" target="_blank" class="text-white grid {{ subitem.img ? 'grid-cols-[minmax(0,30px)_minmax(0,1fr)]' : 'grid-cols-1' }} p-2 hover:text-primary rounded-lg w-full">
														@if (subitem.img) {
															<div class="w-full text-center">
																<img [src]="subitem.img" alt="icon" class="max-w-full">
															</div>
														}
														<div class="w-full">
															<h5 class="font-medium text-base">{{ subitem.name }}</h5>
															<p *ngIf="subitem.desc" class="font-light text-sm">{{ subitem.desc }}</p>	
														</div>
													</a>
												} @else {
													<a [routerLink]="subitem.url" [queryParams]="subitem.params ?? {}" class="text-white grid {{ subitem.img ? 'grid-cols-[minmax(0,30px)_minmax(0,1fr)]' : 'grid-cols-1' }} p-2 hover:text-primary rounded-lg w-full">
														@if (subitem.img) {
															<div class="w-full text-center">
																<img [src]="subitem.img" alt="icon" class="max-w-full">
															</div>
														}
														<div class="w-full">
															<h5 class="font-medium text-base">{{ subitem.name }}</h5>
															<p *ngIf="subitem.desc" class="font-light text-sm">{{ subitem.desc }}</p>
														</div>
													</a>
												}
											</li>
										}
									</ul>
								</dropdown-menu-cy>	
							} @else {
								<a [routerLink]="item.url" class="text-white font-semibold hover:text-primary">{{ item.name }}</a>
							}
						}
					</li>
				}
			</ul>
		</div>

		<div class="w-auto max-w-full">
			<ul class="list-none flex flex-wrap items-center gap-2 justify-end">
				<li class="relative">
					<ng-container *ngIf="!authService.getAuth(); else elseAuth1">
						<div class="hidden sm:flex sm:flex-wrap sm:gap-2">
							<a routerLink="/auth/login">
								<button-cy type="button">
									<span class="text-white">Iniciar sesión</span>
								</button-cy>
							</a>
							<a routerLink="/auth/register">
								<button-cy type="button" addClass="btn__primary" label="Registrarse" />
							</a>
						</div>
					</ng-container>
					<ng-template #elseAuth1>
						<ng-container *ngIf="user$ | async as _user; else elseNoAuth">
							<ng-template #titleCustom>
								<div class="normal-case text-white text-lg py-2 px-4 inline-flex flex-wrap items-center relative">
									<div class="w-[40px] mr-[10px]">
										<img #userImage [src]="_user.image ?? env.imgLogo" alt="User" (error)="userImage.src = env.imgLogo">
									</div>
									<span class="sm:flex sm:flex-col hidden max-w-[115px]">
										<span [title]="_user.name" class="text-sm block text-left leading-[normal] font-semibold text-ellipsis whitespace-nowrap overflow-hidden max-w-full">{{ _user.name }}</span>
										<!-- <span class="text-[13px] block text-left leading-[normal]">Dashboard</span> -->
									</span>
									<small><i class="fa-light fa-angle-down sm:ml-[8px]"></i></small>
								</div>
							</ng-template>
							<dropdown-menu-cy [title]="titleCustom" [customTitle]="true" position="right">
								<ul class="flex flex-col">
									@if(authService.getAuth()) {
										<li>
											<a routerLink="/account" class="text-white grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-2 hover:text-primary rounded-lg w-full">
												<div class="w-full text-left">
													<i class="fa-regular fa-user"></i>
												</div>
												<div class="w-full">
													<h5 class="font-medium text-base">Mi Cuenta</h5>
												</div>
											</a>
										</li>
										<li *ngIf="_user.memberships.length > 0">
											<div class="text-white grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 w-full">
												<div class="w-full text-left">
													<i class="fa-regular fa-users"></i>
												</div>
												<div class="w-full">
													<h5 class="font-medium text-base">Cambiar perfil</h5>
												</div>
											</div>
											<div class="w-full flex flex-col gap-2 mb-2">
												@for (item of _user.memberships; track $index) {
													<div (click)="changeProfile(item.id)" class="grid grid-cols-[minmax(0,20px)_minmax(0,1fr)] items-center cursor-pointer rounded transition-all" [ngClass]="(_user.membershipCurrent?.id == item.id) ? 'bg-third p-2' : ''">
														<div class="" [ngClass]="(_user.membershipCurrent?.id == item.id) ? 'text-primary' : 'text-white'">
															<i class="fa-light fa-caret-right"></i>
														</div>
														<div class="pl-2 desc__profile w-full type__small">
															<div class="profile__img">
																<img [defaultImage]="env.imgLogo" [errorImage]="env.imgLogo" [lazyLoad]="item?.image ?? ''" alt="Image">
															</div>
															<div class="profile__name">
																<h5 class="w-full truncate">{{ item?.name ?? '-' }}</h5>
																<div *ngIf="item?.suscripcion_name" class="status truncate">
																	{{ item?.suscripcion_name }}
																</div>
															</div>
														</div>
													</div>
												}
											</div>
										</li>
										<li>
											<a (click)="createProfile()" class="text-white grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-2 hover:text-primary rounded-lg w-full cursor-pointer">
												<div class="w-full text-left">
													<i class="fa-regular fa-user-plus"></i>
												</div>
												<div class="w-full">
													<h5 class="font-medium text-base">Añadir perfil</h5>
												</div>
											</a>
										</li>
										<li>
											<button (click)="logout()" class="text-white grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-2 hover:text-primary rounded-lg w-full">
												<div class="w-full text-left">
													<i class="fa-regular fa-arrow-right-from-bracket"></i>
												</div>
												<div class="w-full text-left">
													<h5 class="font-medium text-base">Cerrar sesión</h5>
												</div>
											</button>
										</li>
									}
								</ul>
							</dropdown-menu-cy>
						</ng-container>
						<ng-template #elseNoAuth>
							<div class="hidden sm:flex sm:flex-wrap sm:gap-2">
								<a routerLink="/auth/login">
									<button-cy type="button">
										<span class="text-white">Iniciar sesión</span>
									</button-cy>
								</a>
								<a routerLink="/auth/register">
									<button-cy type="button" addClass="btn__primary" label="Registrarse" />
								</a>
							</div>
						</ng-template>
					</ng-template>
				</li>
			</ul>
		</div>
	</nav>
</header>